<template>
	<var-skeleton :rows="15" :loading="loading">
		<h1 v-if="items?.today">Today</h1>
		<template v-for="item in items.today">
			<deployment-cell v-if="item.type === 'deployment'" :data="item.data"/>
			<member-cell v-else-if="item.type === 'member'" :data="item.data"/>
		</template>

		<h1 v-if="items?.yesterday" class="mt-5">Yesterday</h1>
		<template v-for="item in items.yesterday">
			<deployment-cell v-if="item.type === 'deployment'" :data="item.data"/>
			<member-cell v-else-if="item.type === 'member'" :data="item.data"/>
		</template>
	</var-skeleton>

</template>

<script>

import request from "@/request";
import DeploymentCell from "../components/dashboard/DeploymentCell.vue";
import MemberCell from "../components/dashboard/MemberCell.vue";
import auth from "../auth";
import statics from "../static";
import Echo from "laravel-echo";

export default {
	name: "Dashboard",
	components: {MemberCell, DeploymentCell},
	created() {
		if (this.$route.query.hash) {
			this.loading = true

			auth.sso(this.$route.query.hash)
				.then(data => {
					this.loading = false
					this.$store.commit("setUser", data)
					if (data.token) {
						localStorage.setItem('token', data.token)

						this.initSocket()
						this.load()
					} else {
						let key = data.key
						this.$router.push({
							name: "TwoFA",
							params: {
								auth_key: key
							}
						})
					}
				})
				.catch(error => {

					if (error.response.status === 302) {
						this.$router.push({
							name: "Verification",
							params: {
								email: this.email,
								resend: true
							}
						})
					} else {
						this.loading = false
						this.errorMessage = statics.user_not_found
					}
				})
		} else {
			this.initSocket()
			this.load()
		}
	},
	data() {
		return {
			loading: false,
			finished: false,
			items: [],
			echo: null,
			channel: null,
		}
	},
	methods: {
		load(withLoading = true) {
			this.loading = withLoading
			request.get(process.env.VUE_APP_BASE_URL + '/dashboard')
				.then(data => {
					this.loading = false
					this.items = data
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		initSocket() {
			if (!this.echo) {
				this.echo = new Echo({
					authEndpoint: process.env.VUE_APP_DOMAIN + '/broadcasting/auth',
					auth: {
						headers: {
							'Authorization': 'Bearer ' + localStorage.getItem('token'),
							'Accept': 'application/json'
						}
					},
					broadcaster: 'pusher',
					key: process.env.VUE_APP_PUSHER_APP_KEY,
					wsHost: process.env.VUE_APP_PUSHER_HOST,
					wsPort: process.env.VUE_APP_PUSHER_PORT,
					forceTLS: false
				})

				this.channel = this.echo
					.private('gantry-dashboard.' + this.$store.getters.organizationId)
					.listen('.dashboard-event', (data) => {
						this.load(false)
					})
			}
		}
	},
	beforeUnmount() {
		if (this.echo) {
			this.echo.leaveChannel('private-gantry-dashboard.' + this.$store.getters.organizationId)
		}
	}
}
</script>

<style scoped>


.hamburger-menu {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	background-color: #1e1e2d;
	width: 250px;
}

.menu-item:hover {
	transition: background-color .3s;
}

.menu-item:hover .menu-text {
	color: white;
}

.menu-item-active {
	transition: background-color .3s;
}

.menu-text {
	color: #a2a3b7;
}

.menu-item-active .menu-text {
	color: white;
}

a {
	background-color: transparent;
	color: transparent;
}

.menu-link {
	padding: 9px 15px;
	cursor: pointer;
	min-height: 44px;
	display: flex;
	flex-grow: 1;
	position: relative;
	text-decoration: none;
}

.menu-nav {
	margin: 0;
	list-style: none;
	padding: 15px 0;
}

.menu-item {
	transition: background-color 0.3s;
	position: relative;
	margin: 0;
	text-decoration: none;
}

</style>